/**
 * Footer
 * ===========================================================
 */


.footer-logo-container {
  text-align: center;
}

.footer_logo {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  max-width: 260px;
  background-color: #ffffff;
}