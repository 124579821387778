/**
 * Header styles - top bar, logo, menu, hero and CTA bar
 * ===========================================================
 */


/* Top bar
-------------------------------------------------------------- */

.top-bar_phone-icon,
.top-bar_email-icon {
  margin-bottom: 2px;
  fill: #fff;
  opacity: 0.4;
  vertical-align: middle;
}


/* Logo and menu
-------------------------------------------------------------- */

.menuzord {
  text-align: center;
}

.menuzord-brand {
  margin: 20px auto 0px;
  float: none;
  display: block;
}

.menuzord-menu {
  float: none;
  display: inline-block;
}

@media (min-width: 1024px) {

  .menuzord {
    overflow: hidden;
  }

  .menuzord-brand {
    margin: 13px 0;
    float: left;
    display: block;
  }

  .menuzord-menu {
    margin-top: 5px;
    float: right;
  }

}


/* Hero
-------------------------------------------------------------- */

.hero {

  h1 {
    font-size: 33px;
    text-transform: uppercase;
    line-height: 30px;

    span {
      text-transform: none;
      font-size: 22px;
    }

  }

  h4 {
    font-size: 15px;
  }

  a {
    margin-top: 15px;
  }

}

@media (min-width: 768px) {

  .hero {

    h1 {
      font-size: 40px;
      line-height: 42px;

      span {
        font-size: 32px;
      }

    }

    h4 {
      font-size: 17px;
    }

  }

}


/* CTA bar
-------------------------------------------------------------- */

.call-to-action {

  h3 {
    margin: 0;
    font-size: 18px;
    text-align: center;
  }

}

@media (min-width: 768px) {

    .call-to-action {
      display: flex;
      justify-content: center;

      h3 {
        margin-right: 15px;
        padding-top: 7px;
        font-size: 24px;
        text-align: left;

        br {
          display: none;
        }

      }

    }

}