/**
 * Our Services styles
 * ===========================================================
 */

.our-services_list {
    margin-left: auto;
    margin-right: auto;
    width: 300px;

  li {
    padding: 5px 15px;
    font-size: 14px;
  }

}

@media (min-width: 768px) {

  .our-services_list {
    width: 650px;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 50%;
      font-size: 15px;
      font-weight: bold;
      color: #d5d5d5;
    }

  }

}