/**
 * About Us
 * ===========================================================
 */

.about-us {

  h3 {
    color: #999;
    font-size: 18px;
  }

}

.about-us_list {
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  li {
    padding-top: 7px;
    padding-bottom: 7px;
  }

}

.about-us_photos {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;

  li {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #ffffff;
    display: inline-block;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .about-us_photos_caption {
    display: block;
    color: #555;
    font-weight: bold;
  }

}

@media (min-width: 550px) {

    .about-us_photos {

      li:nth-child(odd) {
        margin-right: 5px;
      }

      li:nth-child(even) {
        margin-left: 5px;
      }

    }

}

@media (min-width: 768px) {

  .about-us {

    h3 {
      color: #999;
      text-align: center;
      font-size: 24px;
    }

  }

  .about-us_list {
    font-size: 16px;
  }

}

@media (min-width: 1200px) {

  .about-us_photos {
    max-width: 100%;

    li:nth-child(odd) {
      margin-right: 0px;
    }

    li:nth-child(even) {
      margin-left: 0px;
    }

    li:nth-child(1),
    li:nth-child(2),
    li:nth-child(3) {
      margin-right: 15px;
    }

  }

}